import React, { Component } from 'react';
import Layout from '../../components/Layout';
import Helmet from "react-helmet";

class ContactPage extends Component {
    render() {
        return (
            <Layout newsletter="true" about="false">
                <Helmet>
                    <title>تماس با ما</title>
                    <link rel="canonical" href={`https://filtershekanha.com/contact`} />
                    <script nonce="TWZDNE5QSUhocnNxc0ZTTFk2RnFLUQ==" src={`/assets/script/openpgp.min.js`} async />
                    <script nonce="TWZDNE5QSUhocnNxc0ZTTFk2RnFLUQ==" src={`/assets/script/encrypt.js`} async />
                </Helmet>
                <div className="row my-5 py-md-5"></div>
                <div className="row justify-content-center">
                    <div className="col-md-9 col-12 my-5 text-center">
                        <h1 className="dir-rtl py-2 c-a">تماس با ما</h1>
                    </div>
                    <div className="col-12 my-4"></div>
                    <div className="col-md-9 col-12 text-justify dir-rtl post my-5">
                        <p className="justify c-a">اگر سوالی داشتید یا اصطلاحا انتقاد و پیشنهادی داشتید، می‌توانید از طریق فرم زیر پیام خودتان را به‌صورت مطمئن و امن برای ما ارسال کنید. توجه داشته باشید که برای ارسال پیام از طریق این فرم، هیچ اجباری برای نوشتن نشانی ایمیل‌تان در فیلد مربوطه نیست و تنها در صورتی که تمایل به دریافت پاسخ داشته باشید، نشانی ایمیل‌تان را برای ما بنویسید.</p>
                        <br />
                        <form name="contact" id="form" method="post" data-netlify="true"  action={`/contact/thanks/`}
                        // data-netlify-honeypot="bot-field" 
                        >
                        {/* <input type="hidden" name="bot-field" /> */}
                        <input type="hidden"  name="form-name" value="contact" />
                        <div className="my-3">
                            <div className="c-a">
                                <label htmlFor="email">نشانی ایمیل:</label>
                            </div>
                            <div>
                                <input type="text" name="email" id="email" placeholder="اختیاری (برای دریافت پاسخ، آدرس ایمیل الزامی است)" />
                            </div>
                        </div>
                        <div className="my-3">
                            <div className="c-a my-1">
                                <label htmlFor="message">متن پیام:</label>
                            </div>
                            <div>
                                <textarea id="message" name="message" placeholder="پیام خود را در این بخش بنویسید..." className="pgp textare-scroll" required={true} />
                            </div>
                        </div>                            
                        <br />
                        <button className="btn-paginate" id="encrypt" type="botton" value="Send Message"  >
                        ارسال پیام
                        </button>
                        </form>
                    </div>
                </div>
                <div className="row my-4 py-md-4"></div>
                
            </Layout>
        )
    }
}

export default ContactPage;
